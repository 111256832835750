
.linage {
    fill: none;
    stroke: #000;
  }
  .marriage {
    fill: none;
    stroke: black;

  }

  /*Div starts with ...*/
  div[class^="woman"]  {
    background-color: pink;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }
  div[class^="man"] {
    background-color: lightblue;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }

/*Div ends with... */
  div[class$="Root"]{
    border-color: crimson;
    border-style: dashed;

  }


  .emphasis {
    font-style: italic;
  }
  .namebox {
    padding: 0;
    margin: 0;
  }
  svg {
    border-style: solid;
    border-width: 1px;
  }
  textarea {
    width: 95%;
    height: 300px;
  }