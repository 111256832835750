.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.center-text {
  text-align: center;
}

.header {
  height: 100%;
  background-color: aqua;
  margin-top: 5px;
}

/* Div des JsonEditors */
div[name="outer-box"] {
  /*margin: 0px in der React-Komponente definiert. Zum Zentrieren deshalb ...*/
  margin: auto !important;
}

#graph svg {
  width: 100%;
  height: 90vh;
}

div[class^="fullscreeen"] svg {
  width: 100vw;
}

.white-icon {
  color: white;
}

/* Umrandung der FontAwesome Icons verhindern*/
.white-icon svg {
  border-width: 0px;
}


.family-node-link-example {
  background-color: pink;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  border-color: crimson;
  border-style: dashed;
  margin: auto;
  display: table;
}


.header-button {

  background: transparent;
  border-width: 0px;
}

.title {
  color: white;

  margin-left: 20px;
  vertical-align: top;
}
